<template>
  <div class="mt-5">
    <div class="uiFrame p-5 mb-5">
      <div class="row">
        <div class="col p-3">
          <h1 class="text-primary m-0 fs-5" v-if="$store.state.user.activated">
            {{ $t("settings") }}
          </h1>
          <a
            class="btn btn-link m-0 p-0"
            :href="
              'https://v.ar-tile.com/#/' + $store.state.user.upload_uid + '/'
            "
            target="_blank"
            v-if="$store.state.user.activated"
          >
            {{ $t("openViewer") }}
          </a>
          <label
            class="custom-file-upload uiFrame p-2 text-center mt-2"
            v-if="$store.state.user.activated"
          >
            <input
              type="file"
              @change="uploadLogo"
              accept="image/png"
              style="border: 1px solid black"
            />
            <p class="small m-0">
              <i class="fas fa-pencil-alt"></i>
              {{ $t("clickToChangeLogo") }}
            </p>
            <img
              :src="$store.state.user.logo"
              style="max-height: 50px; max-width: 100%"
            />
            <p class="small m-0">
              {{ $t("pngOnly") }}
            </p>
          </label>
        </div>
        <div class="col p-3">
          <h1 class="text-primary m-0 fs-5">{{ $t("license") }}</h1>
          <div v-if="refreshing">
            <i class="fas fa-sync fa-spin"></i>
          </div>
          <div v-if="!refreshing">
            <!-- When subscribed show change payment and cancel subscription //-->
            <!--
            <div
              v-if="
                $store.state.user.activated &&
                $store.state.user.status != 'deleted' &&
                $store.state.user.status != 'infinite'
              "
            >
              <button
                class="btn btn-link m-0 p-0"
                v-on:click="openChangePayment"
              >
                {{ $t("changePayment") }}
              </button>
              <br />
              <button class="btn btn-link m-0 p-0" v-on:click="openCancel">
                {{ $t("cancelSubscription") }}
              </button>
            </div>
            //-->
            <!-- When still active but canceled subscription //-->
            <!--
            <div
              v-if="
                $store.state.user.activated &&
                $store.state.user.status == 'deleted'
              "
            >
              <button class="btn btn-link m-0 p-0" v-on:click="openPaddle">
                {{ $t("startNewTrial") }}
              </button>
              <br />
              {{ $t("continueUsage", { date: $store.state.user.endDate }) }}
            </div>
            //-->
            <!-- When no active subscription //-->
            <!--
            <div v-if="!$store.state.user.activated">
              <button class="btn btn-link m-0 p-0" v-on:click="openPaddle">
                {{ $t("startNewTrial") }}
              </button>
            </div>
            //-->
            <!-- When infinite member //-->
            <div v-if="!$store.state.user.activated">
              {{ $t("processingOrder") }}
            </div>
            <div
              v-if="
                $store.state.user.activated &&
                $store.state.user.status == 'infinite'
              "
            >
              {{ $t("infiniteCustomer") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="uiFrame p-5 mb-5 text-center" v-if="thankYou">
      <h1 class="text-primary m-0 mb-3 fs-5">{{ $t("thankYou") }}</h1>
      {{ $t("forYourPurchase") }}<br />
      <button class="btn btn-link m-0 p-0">{{ $t("gettingStarted") }}</button>
    </div>//-->

    <!--<div
      class="uiFrame p-5 mb-5 text-center"
      v-if="!$store.state.user.activated"
    >
      <h1 class="text-primary m-0 mb-3 fs-5">{{ $t("subscribe") }}</h1>
      <button class="btn btn-primary rounded-pill" v-on:click="openPaddle">
        {{ $t("startTrial") }}
      </button>
    </div>//-->

    <div
      class="uiFrame p-5 mb-5 text-center"
      v-if="uploadCounter == 0 && $store.state.user.activated"
    >
      <div class="row text-start">
        <div class="col-6">
          <label class="form-label mt-2">{{ $t("category") }}</label>
          <select
            class="form-select transparent-input"
            v-model="defaultParam.category"
          >
            <option
              v-for="(key, index) in this.$store.state.category"
              :value="key.value"
              v-bind:key="index"
            >
              {{ $t(key.text) }}
            </option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label mt-2">{{ $t("patern") }}</label>
          <select
            class="form-select transparent-input"
            v-model="defaultParam.patern"
          >
            <option
              v-for="(key, index) in this.$store.state.paternTypes"
              :value="key.value"
              v-bind:key="index"
            >
              {{ $t(key.text) }}
            </option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label mt-2">{{ $t("tileWidth") }}</label>
          <input
            type="text"
            class="form-control"
            :placeholder="$t('tileWidth')"
            v-model="defaultParam.tileWidth"
          />
        </div>
        <div class="col-6">
          <label class="form-label mt-2">{{ $t("tileDepth") }}</label>
          <input
            type="text"
            class="form-control"
            :placeholder="$t('tileDepth')"
            v-model="defaultParam.tileDepth"
          />
        </div>
        <div v-if="defaultParam.patern == 'Seamless'">
          Match the tile width and height according to the seamless patern. (If
          the image shows 1 tile and a halve use 1.5 the size of the tile +
          grout)
        </div>
        <div class="col-6" v-if="defaultParam.patern != 'Seamless'">
          <label for="customRange1" class="form-label mt-2"
            >{{ $t("grout") }} ({{ defaultParam.grout }} mm)</label
          >
          <input
            type="range"
            class="form-range"
            id="customRange1"
            v-model="defaultParam.grout"
            min="0"
            max="20"
            step="1"
          />
        </div>
        <div class="col-6" v-if="defaultParam.patern != 'Seamless'">
          <label class="form-label mt-2">{{ $t("grout-color") }}</label>

          <select
            class="form-select transparent-input"
            v-model="defaultParam.groutColor"
          >
            <option
              v-for="(key, index) in this.$store.state.groutColors"
              :value="key.value"
              v-bind:key="index"
            >
              {{ $t(key.text) }}
            </option>
          </select>
        </div>
      </div>
      <label class="custom-file-upload uiFrame p-5 text-center mt-5">
        <input type="file" @change="onDrop" accept="image/*" multiple />
        <h2 class="text-primary m-0 fs-5">{{ $t("dropFilesHere") }}</h2>
        <p class="small m-0">{{ $t("orClick") }}</p>
      </label>
    </div>
    <div
      class="uiFrame p-5 text-center mb-5"
      v-if="$store.state.user.activated"
    >
      <h1 class="text-primary fs-5">{{ $t("uploadMonitor") }}</h1>
      <div v-for="item in images" :key="item.id" class="border-bottom">
        <div class="row">
          <div
            class="col text-start"
            :class="{ 'text-danger': item.error != '' }"
          >
            {{ item.imageData.name }}
          </div>
          <div class="col text-end">
            <p
              class="m-0"
              v-if="item.error == '' && item.uploadValue.toFixed() < 100"
            >
              {{ $t("progress") }}: {{ item.uploadValue.toFixed() + "%" }}
            </p>
            <p
              class="text-success m-0"
              v-if="item.error == '' && item.uploadValue.toFixed() == 100"
            >
              {{ item.uploadValue.toFixed() + "%" }} {{ $t("done") }}
            </p>
            <p class="text-danger m-0" v-if="item.error != ''">
              {{ $t(item.error) }}
            </p>
            <progress
              id="progress"
              :value="item.uploadValue"
              max="100"
              v-if="item.error == '' && item.uploadValue.toFixed() < 100"
            ></progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { getFirestore, getDoc, doc, setDoc } from "firebase/firestore";

import swal from "sweetalert";

export default {
  name: "HomeView",
  data() {
    return {
      uploadCounter: 0,
      logoUploadProgress: 0,
      images: [],
      thankYou: false,
      refreshing: false,
      defaultParam: {
        tileWidth: 300,
        tileDepth: 300,
        grout: 3,
        groutColor: "Light",
        floorWidth: 2,
        floorDepth: 2,
        patern: "Grid",
        category: "Tile",
      },
      useDefault: true,
    };
  },
  mounted: function () {
    this.resetDefault();
    this.getLicenseInfo();
  },
  methods: {
    resetDefault() {
      this.defaultParam = {
        tileWidth: 300,
        tileDepth: 300,
        grout: 3,
        groutColor: "Light",
        floorWidth: 2,
        floorDepth: 2,
        patern: "Grid",
        category: "Tile",
      };
    },
    async getLicenseInfo(p_shouldBe) {
      this.refreshing = true;
      p_shouldBe = p_shouldBe || "";
      const db = getFirestore();
      const docSnap = await getDoc(
        doc(db, "users", this.$store.state.user.uid)
      );
      if (docSnap.exists()) {
        const info = docSnap.data();
        if (info.status != "") {
          this.$store.commit("setUserSubscriptionState", info);
          if (!info.email) {
            this.setMail();
          }
          if (info.status != "infinite") {
            const docSnap2 = await getDoc(
              doc(
                db,
                "users",
                this.$store.state.user.uid,
                "subscriptions",
                info.subscription_id
              )
            );
            if (docSnap2.exists()) {
              const subscription_info = docSnap2.data();
              this.$store.commit("setUserSubscriptionLinks", subscription_info);
              this.reRunLicenseInfo(p_shouldBe);
            } else {
              console.log("No such document!");
              this.reRunLicenseInfo(p_shouldBe);
            }
          }
        }
        this.reRunLicenseInfo(p_shouldBe);
      } else {
        console.log("No such document!");
        this.reRunLicenseInfo(p_shouldBe);
      }
    },
    reRunLicenseInfo(p_shouldBe) {
      var self = this;
      if (p_shouldBe != "" && p_shouldBe != this.$store.state.user.status)
        setTimeout(() => {
          self.getLicenseInfo(p_shouldBe);
        }, 1000);
      else {
        this.refreshing = false;
      }
    },
    uploadLogo(event) {
      const image = event.target.files[0];
      const storage = getStorage();
      const storageRef = ref(
        storage,
        "Logo/" + this.$store.state.user.upload_uid + "/logo.png"
      );
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.logoUploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            this.createLogo(downloadURL);
          });
        }
      );
    },
    onDrop(event) {
      if (this.uploadCounter == 0) {
        this.images = [];
        /*
        LIMIT NUMBER OF FILES ?
        */
        for (var i = 0; i < event.target.files.length; i++) {
          const obj = {
            id: i,
            imageData: event.target.files[i],
            picture: null,
            uploadValue: 0,
            show: true,
            error: "",
          };
          this.images.push(obj);
          this.uploadCounter++;
          this.startUpload(i);
        }
      }
    },
    startUpload(index) {
      this.images[index].picture = null;
      const storage = getStorage();
      const storageRef = ref(
        storage,
        "TileImages/" +
          this.$store.state.user.upload_uid +
          "/" +
          this.images[index].imageData.name
      );
      const uploadTask = uploadBytesResumable(
        storageRef,
        this.images[index].imageData
      );
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.images[index].uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
          this.images[index].error = "toBigOrNotAnImage";
          this.uploadCounter--;
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          this.images[index].show = false;
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            this.createTileConfig(
              this.images[index].imageData.name,
              downloadURL
            );
            this.uploadCounter--;
          });
        }
      );
    },
    async createTileConfig(tileName, downloadURL) {
      const db = getFirestore();
      const keyName = tileName.replace(/\.[^/.]+$/, "");
      const docSnap = await getDoc(
        doc(db, "users", this.$store.state.user.upload_uid, "tiles", keyName)
      );
      if (!docSnap.exists() || this.useDefault) {
        await setDoc(
          doc(db, "users", this.$store.state.user.upload_uid, "tiles", keyName),
          {
            id: keyName,
            image: tileName,
            tileWidth: this.defaultParam.tileWidth,
            tileDepth: this.defaultParam.tileDepth,
            grout: this.defaultParam.grout,
            groutColor: this.defaultParam.groutColor,
            floorWidth: this.defaultParam.floorWidth,
            floorDepth: this.defaultParam.floorDepth,
            patern: this.defaultParam.patern,
            picture: downloadURL,
            category: this.defaultParam.category,
          },
          { merge: true }
        );
      } else {
        await setDoc(
          doc(db, "users", this.$store.state.user.upload_uid, "tiles", keyName),
          {
            image: tileName,
            picture: downloadURL,
          },
          { merge: true }
        );
      }
    },
    async createLogo(downloadURL) {
      const db = getFirestore();
      this.$store.commit("setUserLogo", downloadURL);
      await setDoc(
        doc(db, "users", this.$store.state.user.upload_uid),
        {
          logo: downloadURL,
        },
        { merge: true }
      );
    },
    async setMail() {
      const db = getFirestore();
      await setDoc(
        doc(db, "users", this.$store.state.user.uid),
        {
          email: this.$store.state.user.mail,
        },
        { merge: true }
      );
    },
    openPaddle() {
      var self = this;
      const el = document.createElement("div");
      el.innerHTML = this.$t("acceptTerms");
      swal({
        title: this.$t("acceptTermsTitle"),
        content: el,
        icon: "info",
        buttons: true,
        dangerMode: false,
      }).then((onOK) => {
        if (onOK) {
          // eslint-disable-next-line
      Paddle.Checkout.open({
            product: 757270,
            email: this.$store.state.user.mail,
            passthrough: this.$store.state.user.uid,
            successCallback: function (data) {
              console.log(data);
              self.getLicenseInfo("trialing");
              self.thankYou = true;
            },
            closeCallback: function (data) {
              console.log(data);
            },
          });
        }
      });
    },
    openChangePayment() {
      // eslint-disable-next-line
      Paddle.Checkout.open({
        override: this.$store.state.user.update_url,
      });
    },
    openCancel() {
      var self = this;
      // eslint-disable-next-line
      Paddle.Checkout.open({
        override: this.$store.state.user.cancel_url,
        successCallback: function (data) {
          console.log(data);
          self.getLicenseInfo("deleted");
        },
        closeCallback: function (data) {
          console.log(data);
        },
      });
    },
  },
};
</script>
