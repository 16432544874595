<template>
  <div class="mt-5">
    <div class="uiFrame p-5 pb-3 text-center">
      <h1 class="text-primary">{{ $t("resetPassword") }}</h1>
      <input
        class="form-control mb-3 transparent-input rounded-pill text-center"
        type="text"
        :placeholder="$t('email')"
        v-model="email"
      />
      <div class="mb-3">
        <button
          v-on:click="sendLink"
          class="btn btn-primary rounded-pill ps-5 pe-5"
        >
          {{ $t("sendResetLink") }}
        </button>
      </div>
      <p>
        <router-link to="/login">{{ $t("waitIremember") }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
// @ is an alias to /src

export default {
  name: "ForgotView",
  data() {
    return {
      email: "",
    };
  },
  components: {},
  methods: {
    sendLink: function () {
      const auth = getAuth();
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          // Password reset email sent!
          // ..
          this.$router.replace("login");
        })
        .catch(() => {
          // ..
          this.$router.replace("login");
        });
    },
  },
};
</script>
