<template>
  <div class="mt-5">
    <div
      class="uiFrame p-5 text-center mb-5"
      v-if="$store.state.user.activated"
    >
      <h1 class="text-primary fs-5">{{ $t("tileManager") }}</h1>
      <div class="row">
        <div class="col-6 col-md-3 p-2" v-for="item in tileList" :key="item.id">
          <div class="uiFrame p-3 text-center">
            <div style="overflow: hidden; height: 150px">
              <img class="img-fluid" :src="item.picture" />
            </div>
            {{ item.id }}
            <div class="text-start">
              <label class="form-label">{{ $t("category") }}</label>
              <select
                class="form-select transparent-input mb-3"
                v-model="item.category"
              >
                <option
                  v-for="(key, index) in this.$store.state.category"
                  :value="key.value"
                  v-bind:key="index"
                >
                  {{ $t(key.text) }}
                </option>
              </select>

              <label class="form-label">{{ $t("tileWidth") }}</label>
              <input
                type="text"
                class="form-control mb-3"
                :placeholder="$t('tileWidth')"
                v-model="item.tileWidth"
              />

              <label class="form-label">{{ $t("tileDepth") }}</label>
              <input
                type="text"
                class="form-control mb-3"
                :placeholder="$t('tileDepth')"
                v-model="item.tileDepth"
              />

              <label class="form-label">{{ $t("patern") }}</label>
              <select
                class="form-select transparent-input mb-3"
                v-model="item.patern"
              >
                <option
                  v-for="(key, index) in this.$store.state.paternTypes"
                  :value="key.value"
                  v-bind:key="index"
                >
                  {{ $t(key.text) }}
                </option>
              </select>

              <label for="customRange1" class="form-label"
                >{{ $t("grout") }} ({{ item.grout }} mm)</label
              >
              <input
                type="range"
                class="form-range mb-3"
                id="customRange1"
                v-model="item.grout"
                min="0"
                max="20"
                step="1"
              />

              <label class="form-label">{{ $t("grout-color") }}</label>
              <select
                class="form-select transparent-input mb-3"
                v-model="item.groutColor"
              >
                <option
                  v-for="(key, index) in this.$store.state.groutColors"
                  :value="key.value"
                  v-bind:key="index"
                >
                  {{ $t(key.text) }}
                </option>
              </select>
            </div>
            <button
              class="btn btn-danger rounded-pill me-2"
              v-on:click="deleteItem(item)"
            >
              <i class="fas fa-trash"></i>
            </button>
            <button
              class="btn btn-success rounded-pill"
              v-on:click="saveItem(item)"
            >
              <i
                class="fas fa-save"
                v-if="!this.saving[item.id] || this.saving[item.id] === 0"
              ></i>
              <i
                class="fas fa-sync fa-spin"
                v-if="this.saving[item.id] === 1"
              ></i>
              <i class="fas fa-check" v-if="this.saving[item.id] === 2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  getStorage,
  ref,
  //getDownloadURL,
  deleteObject,
} from "firebase/storage";

import {
  getFirestore,
  getDoc,
  doc,
  collection,
  query,
  orderBy,
  startAfter,
  limit,
  getDocs,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

import swal from "sweetalert";

export default {
  name: "TileView",
  data() {
    return {
      tileList: [], //config
      saving: {},
    };
  },
  mounted: function () {
    if (!this.$store.state.user.activated) this.getLicenseInfo();
    this.listTiles();
  },
  methods: {
    async deleteItem(item) {
      swal({
        title: this.$t("delete", { id: item.id }),
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (onOK) => {
        if (onOK) {
          const db = getFirestore();
          await deleteDoc(
            doc(
              db,
              "users",
              this.$store.state.user.upload_uid,
              "tiles",
              item.id
            )
          );
          const storage = getStorage();
          const storageRef = ref(
            storage,
            "TileImages/" + this.$store.state.user.upload_uid + "/" + item.image
          );
          await deleteObject(storageRef);
          this.tileList.splice(
            this.tileList.findIndex((v) => v.id === item.id),
            1
          );
        }
      });
      /*if (confirm(this.$t("delete", { id: item.id })) == true) {
        const db = getFirestore();
        await deleteDoc(
          doc(db, "users", this.$store.state.user.uid, "tiles", item.id)
        );
        const storage = getStorage();
        const storageRef = ref(
          storage,
          "TileImages/" + this.$store.state.user.uid + "/" + item.image
        );
        await deleteObject(storageRef);
        this.tileList.splice(
          this.tileList.findIndex((v) => v.id === item.id),
          1
        );
      }*/
    },
    async saveItem(item) {
      const db = getFirestore();
      this.saving[item.id] = 1;
      await setDoc(
        doc(db, "users", this.$store.state.user.upload_uid, "tiles", item.id),
        {
          ...item,
        },
        { merge: true }
      );
      this.savingOK(item.id);
    },
    savingOK(itemId) {
      this.saving[itemId] = 2;
      setTimeout(() => {
        this.saving[itemId] = 0;
      }, 2000);
    },
    async getLicenseInfo() {
      const db = getFirestore();
      const docSnap = await getDoc(
        doc(db, "users", this.$store.state.user.uid)
      );
      if (docSnap.exists()) {
        const info = docSnap.data();
        this.$store.commit("setUserSubscriptionState", info);
      } else {
        console.log("No such document!");
        this.$router.replace("home");
      }
    },
    /*getPicture(itemRef) {
      const storage = getStorage();
      const storageRef = ref(
        storage,
        "TileImages/" + this.$store.state.user.uid + "/" + itemRef.image
      );
      getDownloadURL(storageRef).then((downloadURL) => {
        var item = this.tileList.filter((obj) => {
          return obj.image === itemRef.image;
        });
        item[0].picture = downloadURL;
      });
    },*/
    async listTiles(p_limit) {
      this.tileList = [];
      p_limit = p_limit || 25;
      const db = getFirestore();
      // Query the first page of docs
      const first = query(
        collection(db, "users", this.$store.state.user.upload_uid, "tiles"),
        orderBy("id"),
        limit(p_limit)
      );
      const documentSnapshots = await getDocs(first);
      documentSnapshots.forEach((doc) => {
        this.tileList.push(doc.data());
        //this.getPicture(doc.data());
      });
      if (documentSnapshots.docs.length == p_limit) {
        //there might be more pages
        // Get the last visible document
        const lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
        this.nextTiles(lastVisible, p_limit);
      }
    },
    async nextTiles(after, p_limit) {
      const db = getFirestore();
      const next = query(
        collection(db, "users", this.$store.state.user.upload_uid, "tiles"),
        orderBy("id"),
        startAfter(after),
        limit(p_limit)
      );
      const documentSnapshots = await getDocs(next);
      documentSnapshots.forEach((doc) => {
        this.tileList.push(doc.data());
        //this.getPicture(doc.data());
      });
      if (documentSnapshots.docs.length == p_limit) {
        //there might be more pages
        // Get the last visible document
        const lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
        this.nextTiles(lastVisible, p_limit);
      }
    },
  },
};
</script>
