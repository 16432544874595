import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "bootstrap";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, getRedirectResult } from "firebase/auth";

let app = createApp(App).use(i18n).use(store).use(router);

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCekNAfjT3JncOiHoJIdy8SqngOQrKqQHw",
  authDomain: "ar-tile.firebaseapp.com",
  projectId: "ar-tile",
  storageBucket: "ar-tile.appspot.com",
  messagingSenderId: "449683227365",
  appId: "1:449683227365:web:51cfe16070555b22f78a0c",
  measurementId: "G-ET748BGS76",
};

const firebaseApp = initializeApp(firebaseConfig);
app.config.globalProperties.$firebaseApp = firebaseApp;
app.mount("#app");
//const analytics = getAnalytics(app);
const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    store.commit("setUserID", user.uid);
    getAuth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        if (idTokenResult.claims.email)
          store.commit("setUserMail", idTokenResult.claims.email);
        else store.commit("setUserMail", "");
        if (idTokenResult.claims.stripeRole)
          store.commit("setUserStripeRole", idTokenResult.claims.stripeRole);
        else store.commit("setUserStripeRole", "");
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

//Not shure why yet
getRedirectResult(auth)
  .then(() => {
    router.replace("home");
  })
  .catch((error) => {
    alert(error.message);
    // ...
  });
