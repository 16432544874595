export default {
  "needSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need support?"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your API key"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "dropFilesHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop files here to upload them"])},
  "orClick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(or click)"])},
  "clickToChangeLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here"])},
  "openViewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open AR-Tile viewer"])},
  "pngOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(.png only)"])},
  "uploadMonitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload monitor"])},
  "tileManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your tiles"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
  "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["done"])},
  "toBigOrNotAnImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Big or not an image!"])},
  "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription details"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "orSignInWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or sign in with"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account?"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])},
  "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send reset link"])},
  "waitIremember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh wait! I remember it"])},
  "newAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "alreadyHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start your 14 day free trial now!"])},
  "startTrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here!"])},
  "changePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change payment method"])},
  "cancelSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel subscription"])},
  "continueUsage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Re-subscribe before ", _interpolate(_named("date")), " to continue using our platform."])},
  "startNewTrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe now"])},
  "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
  "forYourPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are glad to welcome you as a customer."])},
  "infiniteCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual billing"])},
  "gettingStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick start guide"])},
  "tileWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tile width (mm)"])},
  "tileDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tile depth (mm)"])},
  "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stack"])},
  "brickwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwork"])},
  "seamless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seamless"])},
  "Checkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkers"])},
  "patern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patern"])},
  "grout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grout"])},
  "grout-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grout color"])},
  "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark"])},
  "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "tile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tile"])},
  "ceramic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceramic"])},
  "natural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural"])},
  "cement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cement"])},
  "laminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laminate"])},
  "parquet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parquet"])},
  "cork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cork"])},
  "vinyl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinyl"])},
  "carpet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carpet"])},
  "concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concrete"])},
  "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete tile '", _interpolate(_named("id")), "'?"])},
  "acceptTermsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One more thing!"])},
  "acceptTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking OK you will accept our <a href='https://ar-tile.com/terms.html' target=_blank>terms and conditions</a>!"])},
  "processingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are setting up your environment and contact you as soon as possible."])}
}