import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TileView from "../views/TileView.vue";
import LoginView from "../views/LoginView.vue";
import SignUpView from "../views/SignUpView.vue";
import Forgot from "../views/ForgotView.vue";
import { getAuth } from "firebase/auth";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/home",
    name: "Home",
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tiles",
    name: "Tiles",
    component: TileView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUpView,
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: Forgot,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  //If you link to a path that requires authentication and there is no currentUser or the current user is Anonymous, you need to login first
  if (requiresAuth && (!currentUser || currentUser.isAnonymous)) next("login");
  // If the path is not required to Login but there is already a current user and that user is not an anonymous one you can go directly to the protected home page
  else if (!requiresAuth && currentUser && !currentUser.isAnonymous)
    next("home");
  // else just go to a protected page when you are logged in and are not an anonymous user.
  else next();
});

export default router;
