<template>
  <div class="mt-5">
    <div class="uiFrame p-5 pb-3 text-center">
      <h1 class="text-primary">{{ $t("newAccount") }}</h1>
      <input
        class="form-control mb-3 transparent-input rounded-pill text-center"
        type="text"
        :placeholder="$t('email')"
        v-model="email"
      />
      <input
        class="form-control mb-3 transparent-input rounded-pill text-center"
        type="password"
        :placeholder="$t('password')"
        v-model="password"
      />
      <div class="mb-3">
        <button
          v-on:click="signUp"
          class="btn btn-primary rounded-pill ps-5 pe-5"
        >
          {{ $t("signUp") }}
        </button>
      </div>

      <div class="mb-3">- {{ $t("orSignInWith") }} -</div>
      <div class="mb-3">
        <button
          class="btn btn-danger rounded-pill ps-5 pe-5"
          v-on:click="googlelogin"
        >
          <i class="fab fa-google"></i>oogle
        </button>
      </div>

      <div class="row">
        <p class="text-lg-start col-lg-6">
          <router-link to="/forgot">{{ $t("forgotPassword") }}</router-link>
        </p>
        <p class="text-lg-end col-lg-6">
          <router-link to="/login">{{
            $t("alreadyHaveAnAccount")
          }}</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithRedirect,
} from "firebase/auth";
// @ is an alias to /src

export default {
  name: "SignUpView",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  components: {},
  methods: {
    signUp: function () {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          this.$router.replace("home");
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    googlelogin: function () {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithRedirect(auth, provider);
    },
  },
};
</script>
