import { createStore } from "vuex";

export default createStore({
  state: {
    user: {
      uid: "",
      upload_uid: "",
      mail: "",
      stripeRole: "",
      status: "deleted",
      endDate: "",
      subscriptionId: 0,
      cancel_url: "",
      update_url: "",
      next_bill_date: "",
      activated: false,
      logo: "https://firebasestorage.googleapis.com/v0/b/ar-tile.appspot.com/o/Logo%2FDefault%2FARTileDefaultLogo.png?alt=media&token=53fa274f-78da-48e4-82ed-e4438022d65a",
    },
    currentLocal: "en",
    paternTypes: [
      {
        value: "Grid",
        text: "grid",
      },
      {
        value: "Brickwork",
        text: "brickwork",
      },
      /*{
        value: "Checkers",
        text: "checkers",
      },*/
      {
        value: "Seamless",
        text: "seamless",
      },
    ],
    groutColors: [
      {
        value: "Dark",
        text: "dark",
      },
      {
        value: "Light",
        text: "light",
      },
    ],
    category: [
      {
        value: "Tile",
        text: "tile",
      },
      {
        value: "Ceramic",
        text: "ceramic",
      },
      {
        value: "Natural",
        text: "natural",
      },
      {
        value: "Cement",
        text: "cement",
      },
      {
        value: "Laminate",
        text: "laminate",
      },
      {
        value: "Parquet",
        text: "parquet",
      },
      {
        value: "Cork",
        text: "cork",
      },
      {
        value: "Vinyl",
        text: "vinyl",
      },
      {
        value: "Carpet",
        text: "carpet",
      },
    ],
    locals: {
      en: {
        local: "en",
        icon: "https://firebasestorage.googleapis.com/v0/b/ar-tile.appspot.com/o/Flags%2Funited-kingdom-32x32-2903286.png?alt=media&token=f97702ea-751e-4c1f-bca2-78e5430b8e10",
        title: "English",
      },
      fr: {
        local: "fr",
        icon: "https://firebasestorage.googleapis.com/v0/b/ar-tile.appspot.com/o/Flags%2Ffrance-32x32-2903259.png?alt=media&token=1707bb96-8b67-4ed6-b9f6-747e8c9e81ed",
        title: "French",
      },
      nl: {
        local: "nl",
        icon: "https://firebasestorage.googleapis.com/v0/b/ar-tile.appspot.com/o/Flags%2Fnetherlands-32x32-2903269.png?alt=media&token=ddc875a1-96be-4671-a11d-776461ed1de0",
        title: "Nederlands",
      },
    },
  },
  getters: {},
  mutations: {
    setUserID(state, value) {
      state.user.uid = value;
      state.user.upload_uid = value;
    },
    setUploadUserID(state, value) {
      state.user.upload_uid = value;
    },
    setUserMail(state, value) {
      state.user.mail = value;
    },
    setUserStripeRole(state, value) {
      state.user.stripeRole = value;
    },
    setLocal(state, value) {
      state.currentLocal = value;
    },
    setUserSubscriptionState(state, payload) {
      state.user.status = payload.status || "deleted";
      state.user.endDate = payload.cancellation_effective_date || "";
      state.user.subscriptionId = payload.subscription_id || 0;
      state.user.logo =
        payload.logo ||
        "https://firebasestorage.googleapis.com/v0/b/ar-tile.appspot.com/o/Logo%2FDefault%2FARTileDefaultLogo.png?alt=media&token=53fa274f-78da-48e4-82ed-e4438022d65a";
      if (state.user.endDate != "") {
        const d = new Date(state.user.endDate);
        if (
          state.user.status == "active" ||
          state.user.status == "trialing" ||
          state.user.status == "infinite" ||
          (state.user.status == "deleted" && d >= new Date())
        ) {
          state.user.activated = true;
        }
      } else {
        if (
          state.user.status == "active" ||
          state.user.status == "trialing" ||
          state.user.status == "infinite"
        )
          state.user.activated = true;
      }
    },
    setUserSubscriptionLinks(state, payload) {
      state.user.cancel_url = payload.cancel_url || "";
      state.user.update_url = payload.update_url || "";
      state.user.next_bill_date = payload.next_bill_date || "";
    },
    setUserLogo(state, logo) {
      state.user.logo = logo;
    },
  },
  actions: {},
  modules: {},
});
