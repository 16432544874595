<template>
  <div class="container pt-5">
    <div class="uiFrame text-start p-5">
      <div class="row">
        <div class="col text-start">
          <img
            src="@/assets/AR-TileOnDark.png"
            alt="AR-Tile logo"
            height="55"
          />
        </div>
        <div class="col">
          <div
            class="btn-toolbar justify-content-end"
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <div
              class="btn-group me-2"
              role="group"
              aria-label="First group"
              v-if="$store.state.user.uid != ''"
            >
              <button
                type="button"
                class="btn btn-outline-secondary rounded-pill"
                v-on:click="toPage('tiles')"
                v-if="$route.name == 'Home'"
              >
                <i class="fab fa-delicious"></i> {{ $t("tileManager") }}
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary rounded-pill"
                v-on:click="toPage('home')"
                v-if="$route.name == 'Tiles'"
              >
                <i class="fas fa-home"></i> {{ $t("dashboard") }}
              </button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="Second group">
              <button
                type="button"
                class="btn btn-outline-secondary rounded-pill"
                v-on:click="signOut()"
                v-if="$store.state.user.uid != ''"
              >
                <i class="fas fa-sign-out-alt"></i> {{ $t("signOut") }}
              </button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="Second group">
              <button
                id="langDrop1"
                type="button"
                class="btn btn-link rounded-pill dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  :src="selectedFlag"
                  :alt="selectedLocal"
                  class="img-fluid rounded-circle"
                />
              </button>
              <ul class="dropdown-menu" aria-labelledby="langDrop1">
                <li v-for="(item, index) in $store.state.locals" :key="index">
                  <a
                    class="dropdown-item"
                    href="#"
                    v-on:click.prevent="changeLocal(index)"
                  >
                    <img
                      :src="item.icon"
                      alt="Flag"
                      class="img-fluid rounded-circle"
                    />
                    {{ item.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <router-view />
    </div>
    <p class="text-white">
      <a
        href="https://r-devstudio.com"
        target="_blank"
        class="text-white btn btn-link"
        >&copy; R-DevStudio BV</a
      >
      <a
        href="mailto:help@ar-tile.com"
        target="_blank"
        class="text-white btn btn-link"
        >{{ $t("needSupport") }}</a
      >
    </p>
  </div>
</template>

<script>
import { getAuth } from "firebase/auth";

export default {
  name: "App",
  computed: {
    selectedFlag: {
      get() {
        return this.$store.state.locals[this.$store.state.currentLocal].icon;
      },
    },
    selectedLocal: {
      get() {
        return this.$store.state.locals[this.$store.state.currentLocal].local;
      },
    },
  },
  methods: {
    signOut: function () {
      const auth = getAuth();
      auth.signOut().then(() => {
        this.$store.commit("setUserID", "");
        this.$store.commit("setUserStripeRole", "");
        this.$router.replace("login");
      });
    },
    toPage: function (page) {
      this.$router.replace(page);
    },
    changeLocal: function (index) {
      this.$i18n.locale = this.$store.state.locals[index].local;
      this.$store.commit("setLocal", index);
      this.writeCookie("lang", index, 30);
      return false;
    },
    writeCookie: function (cname, cvalue, days) {
      let dt, expires;
      dt = new Date();
      dt.setTime(dt.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + dt.toGMTString();
      document.cookie = cname + "=" + cvalue + expires + "; domain=ar-tile.com";
    },
    getCookie: function (cname) {
      const name = cname + "=";
      const cDecoded = decodeURIComponent(document.cookie); //to be careful
      const cArr = cDecoded.split("; ");
      let res;
      cArr.forEach((val) => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
      });
      return res;
    },
  },
};
</script>

<style lang="scss">
@import "./style/index.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
