export default {
  "needSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide ?"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre clé API"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
  "dropFilesHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposez vos fichiers ici pour les télécharger"])},
  "orClick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ou cliquez ici)"])},
  "clickToChangeLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici"])},
  "openViewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir le visualiseur AR-Tile"])},
  "pngOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Seulement .png)"])},
  "uploadMonitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moniteur de téléchargement"])},
  "tileManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos carreaux"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progrès"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prêt"])},
  "toBigOrNotAnImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trop grand ou pas de silhouette !"])},
  "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'abonnement"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "orSignInWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou connectez-vous avec"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte?"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser votre mot de passe"])},
  "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez le lien"])},
  "waitIremember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh attendez ! Je m'en souviens"])},
  "newAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau compte"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
  "alreadyHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un compte?"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez votre essai gratuit de 14 jours maintenant !"])},
  "startTrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici !"])},
  "changePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mode de paiement"])},
  "cancelSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'abonnement"])},
  "continueUsage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Réinscrivez-vous avant ", _interpolate(_named("date")), " pour continuer à utiliser notre plateforme."])},
  "startNewTrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'abonner maintenant"])},
  "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci beaucoup !"])},
  "forYourPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes heureux de vous accueillir en tant que client."])},
  "infiniteCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation annuelle"])},
  "gettingStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide de rapide"])},
  "tileWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur des carreaux (mm)"])},
  "tileDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profondeur des carreaux (mm)"])},
  "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réticulation"])},
  "brickwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demi-brique"])},
  "seamless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans couture"])},
  "Checkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damier"])},
  "patern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pattern"])},
  "grout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joint"])},
  "grout-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur du joint"])},
  "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark"])},
  "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lumière"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
  "tile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carreaux"])},
  "ceramic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Céramique"])},
  "natural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierre naturelle"])},
  "cement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciment"])},
  "laminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stratifié"])},
  "parquet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parquet"])},
  "cork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liège"])},
  "vinyl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinyle"])},
  "carpet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapis"])},
  "concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Béton"])},
  "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer le carreau '", _interpolate(_named("id")), "' ?"])},
  "acceptTermsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant de continuer!"])},
  "acceptTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous cliquez sur OK, vous acceptez nos <a href='https://ar-tile.com/fr/terms.html' target=_blank>termes et conditions</a>!"])},
  "processingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous mettons en place votre environnement et vous contacterons dès que possible."])}
}