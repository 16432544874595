export default {
  "needSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hulp nodig?"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw API sleutel"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
  "dropFilesHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop hier uw bestanden om ze te uploaden"])},
  "orClick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(of klik hier)"])},
  "clickToChangeLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier"])},
  "openViewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR-Tile viewer openen"])},
  "pngOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Enkel .png)"])},
  "uploadMonitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload monitor"])},
  "tileManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw tegels"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
  "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vooruitgang"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klaar"])},
  "toBigOrNotAnImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te groot of geen figuur!"])},
  "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementsgegevens"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
  "orSignInWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of meld aan met"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account aanmaken?"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset uw wachtwoord"])},
  "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur link"])},
  "waitIremember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh wacht! Ik herinner het me"])},
  "newAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw account"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
  "alreadyHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reeds een account?"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start nu uw 14 dagen gratis proefperiode!"])},
  "startTrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier!"])},
  "changePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig betalingsmethode"])},
  "cancelSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement opzeggen"])},
  "continueUsage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Herabonneer voor ", _interpolate(_named("date")), " om ons platform te blijven gebruiken."])},
  "startNewTrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonneer nu"])},
  "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hartelijk dank!"])},
  "forYourPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We zijn blij u als klant te mogen verwelkomen."])},
  "infiniteCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaarlijkse facturatie"])},
  "gettingStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snelstartgids"])},
  "tileWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegelbreedte (mm)"])},
  "tileDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegeldiepte (mm)"])},
  "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kruisverband"])},
  "brickwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halfsteensverband"])},
  "seamless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naadloos"])},
  "Checkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dambord"])},
  "patern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patroon"])},
  "grout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg"])},
  "grout-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voegkleur"])},
  "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donker"])},
  "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licht"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
  "tile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegel"])},
  "ceramic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keramische tegels"])},
  "natural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natuursteen"])},
  "cement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cement"])},
  "laminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laminaat"])},
  "parquet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parket"])},
  "cork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurk"])},
  "vinyl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinyl"])},
  "carpet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapijt"])},
  "concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beton"])},
  "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weet u zeker dat u tegel '", _interpolate(_named("id")), "' wilt verwijderen?"])},
  "acceptTermsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voordat u doorgaat!"])},
  "acceptTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u klikt op OK aanvaard u onze <a href='https://ar-tile.com/nl/terms.html' target=_blank>algemene voorwaarden</a>!"])},
  "processingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We stellen uw omgeving in en nemen zo snel mogelijk contact met u op."])}
}